<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Area</label>
                    <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="filters.provinsi_id" :loading="loadingDropdownProvinsi"
                        :options="dataDropdownProvinsi"
                        optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
                        :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'filter')"
                        @change="changeHomebase()" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Homebase</label>
                    <Dropdown id="homebase_id"  dataKey="homebase_id" v-model="filters.homebase_id" :loading="loadingDropdownHomebase"
                        :options="dataDropdownHomebase"
                        optionLabel="homebase_name" optionValue="homebase_id" placeholder="Pilih Homebase"
                        :filter="true" :showClear="true" @filter="searchDropdownHomebase($event, 'filter')"
                    />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownProvinsi: false,
            loadingDropdownHomebase: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownHomebase: null,

            // filter
            filters: {
                provinsi_id: null,
                homebase_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownProvinsi('');
        this.searchDropdownHomebase('');
    },
    created(){
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeHomebase() {
            this.filters.homebase_id = null;
            this.dataDropdownHomebase = null;
            this.searchDropdownHomebase('');
        },
        searchDropdownHomebase(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dhomebase.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownHomebase = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/homebase',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.filters.provinsi_id,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownHomebase = res.data.data;
                        this.loadingDropdownHomebase = false;
                    } else if (purpose == null) {
                        this.dataDropdownHomebase = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

